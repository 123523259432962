import React from "react";

const HeaderMessage = props => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className={`alert alert-${props.type} alert-dismissable`}>
          <button
            className="close"
            type="button"
            data-dismiss="alert"
            aria-hidden="true"
            onClick={props.onCloseError}
          >
            ×
          </button>
          <i className="fa fa-info-circle" />
          <strong> {props.message}</strong>
        </div>
      </div>
    </div>
  );
};

export default HeaderMessage;
