import React, { Component } from "react";
import { withFormik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import isEmpty from "../../validation/is-empty";
import { checkResetToken, setPassword } from "../../actions/auth";

import HeaderMessage from "../HeaderMessage";

export class SetPassword extends Component {
  state = {
    isValid: false,
    alert: false,
    isLoading: true,
    message: "",
    domain: "",
  };

  onSetPassword = () => {
    this.setState({
      isLoading: true,
    });
    setPassword(this.props.match.params.domain, {
      token: this.props.match.params.token,
      password: this.props.values.password,
    }).then(() => {
      this.setState({
        alert: true,
      });
    });
  };

  componentDidMount() {
    if (
      !isEmpty(this.props.match.params.token) &&
      !isEmpty(this.props.match.params.domain)
    ) {
      checkResetToken(
        this.props.match.params.domain,
        this.props.match.params.token
      ).then((resp) => {
        if (resp.data.success) {
          this.setState({
            isValid: true,
            isLoading: false,
            domain: this.props.match.params.domain,
          });
        } else {
          this.setState({
            isValid: false,
            isLoading: false,
            message: resp.data.message,
            domain: this.props.match.params.domain,
          });
        }
      });
    }
  }

  render() {
    const { errors, touched, values } = this.props;
    return (
      <div className="background">
        <div className="container">
          <div className="text-center">
            <div className="text-center">
              <h1>Digital Concierge</h1>
              <img
                src="/images/DC-Logo-420x400.png"
                width="250px"
                alt="DC Logo"
              />
              <p></p>
            </div>
            {this.state.alert && (
              <div className="row">
                <div className="col-lg-12">
                  <div className={`alert alert-success alert-dismissable`}>
                    <button
                      className="close"
                      type="button"
                      data-dismiss="alert"
                      aria-hidden="true"
                      onClick={this.onCloseError}
                    >
                      ×
                    </button>
                    <i className="fa fa-info-circle" />
                    <strong>
                      Your password has been reset. Click <a href="/">here</a>{" "}
                      to sign in.
                    </strong>
                  </div>
                </div>
              </div>
            )}
            {!this.state.isValid && !this.state.isLoading && (
              <HeaderMessage type="danger" message={this.state.message} />
            )}
            {this.state.isValid && !this.state.alert && (
              <h4> Enter your new password.</h4>
            )}
          </div>
          {this.state.isValid && !this.state.alert && (
            <Form className="form-signin">
              <div
                className={`form-group ${
                  errors.password && touched.password ? " has-error" : ""
                }`}
              >
                <label className="sr-only" htmlFor="password">
                  Password
                </label>
                <Field
                  className="form-control"
                  type="password"
                  name="password"
                  placeholder="Password"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="help-block"
                />
              </div>
              <div
                className={`form-group ${
                  errors.confirm && touched.confirm ? " has-error" : ""
                }`}
              >
                <label className="sr-only" htmlFor="confirm">
                  Confirm
                </label>
                <Field
                  className="form-control"
                  type="password"
                  name="confirm"
                  placeholder="confirm"
                />
                <ErrorMessage
                  name="confirm"
                  component="div"
                  className="help-block"
                />
              </div>
              <button
                className="btn btn-lg btn-primary btn-block"
                onClick={this.onSetPassword}
                disabled={
                  isEmpty(values.password) ||
                  isEmpty(values.confirm) ||
                  !isEmpty(errors) ||
                  this.state.isLoading
                }
              >
                {this.state.isLoading ? "Setting Password..." : "Set Password"}
              </button>
            </Form>
          )}
        </div>
      </div>
    );
  }
}

export default withFormik({
  mapPropsToValues: () => {
    return {
      password: "",
      confirm: "",
    };
  },
  validationSchema: Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\D]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, and One Number"
      )
      .required("Password required"),
    confirm: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  }),
})(SetPassword);
