import axios from "axios";

export const authenticate = (domain, user, pass) => {
  console.log(process.env.USER);

  let baseUrl = `https://${domain}.api.digitalconcierge.io`;

  if (domain === "localhost") {
    baseUrl = "http://localhost:3020";
  }

  return axios.post(`${baseUrl}/auth/authenticate`, {
    username: user,
    password: pass,
  });
};

export const getMyInfo = (domain, token) => {
  let baseUrl = `https://${domain}.api.digitalconcierge.io`;

  if (domain === "localhost") {
    baseUrl = "http://localhost:3020";
  }

  return axios.get(`${baseUrl}/users/myInfo`, {
    headers: { "x-access-token": token },
  });
};

export const resetPassword = (domain, email) => {
  let baseUrl = `https://${domain}.api.digitalconcierge.io`;

  if (domain === "localhost") {
    baseUrl = "http://localhost:3020";
  }

  return axios.post(`${baseUrl}/auth/forgot`, {
    email,
  });
};

export const checkResetToken = (domain, token) => {
  let baseUrl = `https://${domain}.api.digitalconcierge.io`;

  if (domain === "localhost") {
    baseUrl = "http://localhost:3020";
  }
  return axios.post(`${baseUrl}/auth/resetToken`, { token });
};

export const setPassword = (domain, params) => {
  let baseUrl = `https://${domain}.api.digitalconcierge.io`;

  if (domain === "localhost") {
    baseUrl = "http://localhost:3020";
  }
  return axios.post(`${baseUrl}/auth/resetPassword`, params);
};

export const isAllowed = (PID, path) => {
  switch (PID) {
    case "0":
    case "5":
      return { allowed: true };
    case "1":
      if (path.indexOf("userPortal") > -1 || path.indexOf("tech") > -1) {
        return { allowed: true };
      } else {
        return { allowed: false, redirect: "/userPortal" };
      }
    case "2":
      if (path.indexOf("tech")) {
        return { allowed: true };
      } else {
        return { allowed: false, redirect: "/tech" };
      }
    case "3":
      if (path.indexOf("userPortal")) {
        return { allowed: true };
      } else {
        return { allowed: false, redirect: "/userPortal/ro/board" };
      }
    default:
      return { allowed: false, redirect: "/" };
  }
};
