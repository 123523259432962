import React, { Component } from "react";
import { Button } from "react-bootstrap";

const hostname = `https://${
  location.hostname.split(".")[0]
}.api.digitalconcierge.io`;

export class Configure extends Component {
  render() {
    return (
      <div className="container">
        <h3>DC Tech iPad Setup</h3>
        <p>
          If the app is not already installed on this iPad, tap the App Store
          button to be directed to the app store.
        </p>
        <img
          src="/images/appstore.svg"
          alt="app store logo"
          onClick={() =>
            (window.location =
              "https://apps.apple.com/us/app/digital-concierge-technician/id1461711582")
          }
          className="clickable"
        />

        <br />
        <br />
        <p>
          Once the app has been download, use the Open App button to launch the
          app. This button automatically configures the app with the server URL
          settings.
        </p>
        <Button
          onClick={() => {
            window.location = `dctech://?serverUrl=${hostname}`;
          }}
        >
          Open App
        </Button>
      </div>
    );
  }
}

export default Configure;
