export const isEmpty = value => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const isNestedEmpty = (obj, level, ...rest) => {
  if (obj === undefined) return true;
  if (rest.length == 0 && obj.hasOwnProperty(level)) return false;
  return isNestedEmpty(obj[level], ...rest);
};

export default isEmpty;
