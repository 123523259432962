import React, { Component } from "react";
import { Router, Route, Switch, withRouter, Redirect } from "react-router-dom";
import history from "./components/History";
import * as Sentry from "@sentry/react";

import Auth from "./components/auth/Auth";
import Reset from "./components/Reset";
import ResetPassword from "./components/auth/ResetPassword";
import SetPassword from "./components/auth/SetPassword";
import ShortLink from "./components/ShortLink";
import Configure from "../tech/components/ipad/Configure";
import CacheBuster from "./components/CacheBuster";
import NewVersion from "./components/NewVersion";

class App extends Component {
  state = {
    initializing: false,
  };

  render() {
    console.log("Auth routes");
    return (
      <Sentry.ErrorBoundary showDialog>
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              // You can decide how and when you want to force reload
              refreshCacheAndReload();
            }

            return (
              <Router history={history}>
                <Switch>
                  <Route exact path="/admin">
                    <Redirect to="/" />
                  </Route>
                  <Route path="/" exact component={Auth} />
                  <Route path="/clear" exact component={Reset} />
                  <Route path="/setup" exact component={Configure} />
                  <Route path="/forgot" exact component={ResetPassword} />
                  <Route
                    path="/reset/:domain/:token"
                    exact
                    component={SetPassword}
                  />
                  <Route path="/link/:code" exact component={ShortLink} />
                  <Route path="/refresh/test" exact component={NewVersion} />
                </Switch>
              </Router>
            );
          }}
        </CacheBuster>
      </Sentry.ErrorBoundary>
    );
  }
}

export default withRouter(App);
