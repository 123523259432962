import React from "react";
import { Field } from "formik";
import isEmpty from "../../validation/is-empty";
import get from "lodash/get";

const Input = (props) => {
  const { errors, name, placeholder, style, overload } = props;
  return (
    <div
      style={style}
      className={`form-group ${
        !isEmpty(errors) && get(errors, name) ? " has-error" : ""
      }`}
    >
      <Field
        data-testid={name}
        className="form-control"
        componenet="text"
        autoComplete="off"
        name={name}
        placeholder={placeholder}
        {...overload}
      />
      {get(errors, name) ? (
        <div className="help-block" style={{ color: "#a94442" }}>
          {get(errors, name)}
        </div>
      ) : null}
    </div>
  );
};

export default Input;
