import React, { Component } from "react";

const hostname = `https://${
  location.hostname.split(".")[0]
}.api.digitalconcierge.io`;

export class ShortLink extends Component {
  state = {
    link: null,
  };

  componentDidMount() {
    fetch(`${hostname}/link/${this.props.match.params.code}`)
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        this.setState({ link: data });
      });
  }

  render() {
    if (!this.state.link) {
      return <div>Loading Link</div>;
    }

    window.location.replace(this.state.link);
  }
}

export default ShortLink;
