import React, { Component } from "react";
import { withFormik, Form, Field, ErrorMessage } from "formik";

import isEmpty from "../../validation/is-empty";
import { resetPassword } from "../../actions/auth";

import HeaderMessage from "../HeaderMessage";

export class ResetPassword extends Component {
  state = {
    alert: false,
    isLoading: false,
  };

  onResetPassword = () => {
    let domain = location.hostname.split(".")[0];
    this.setState({
      isLoading: true,
    });
    resetPassword(domain, this.props.values.email).then(() => {
      this.setState({
        isLoading: false,
        alert: true,
      });
    });
  };

  render() {
    const { errors, touched, values } = this.props;
    return (
      <div className="background">
        <div className="container">
          <div className="text-center">
            <div className="text-center">
              <h1>Digital Concierge</h1>
              <img
                src="/images/DC-Logo-420x400.png"
                width="250px"
                alt="DC Logo"
              />
              <p></p>
            </div>
            {this.state.alert && (
              <HeaderMessage
                type="success"
                message="If that email address is valid, you will receive a password reset"
              />
            )}
            {!this.state.alert && (
              <h4>
                {" "}
                Enter the email address associated with your account to procede
                with the password reset.
              </h4>
            )}
          </div>
          {!this.state.alert && (
            <Form className="form-signin">
              <div
                className={`form-group ${
                  errors.email && touched.email ? " has-error" : ""
                }`}
              >
                <label className="sr-only" htmlFor="inputEmail">
                  Email Address
                </label>
                <Field
                  className="form-control"
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  required=""
                  autoFocus=""
                  autoCapitalize="none"
                  autoCorrect="off"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="help-block"
                />
              </div>
              <button
                className="btn btn-lg btn-primary btn-block"
                onClick={this.onResetPassword}
                disabled={isEmpty(values.email) || this.state.isLoading}
              >
                {this.state.isLoading ? "Resetting..." : "Reset Password"}
              </button>
            </Form>
          )}
        </div>
      </div>
    );
  }
}

export default withFormik({
  mapPropsToValues: () => {
    return {
      email: "",
    };
  },
})(ResetPassword);
